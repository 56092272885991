import {createRouter, createWebHistory} from 'vue-router'
import {Auth} from "@/functions/Auth";
import RouteView from "@/router/route-view.vue";
import AuthRoutes from "@/router/global/AuthRoutes";
import EmBreveRoutes from "@/router/global/EmBreveRoutes";
import PerfilRoutes from "@/router/global/PerfilRoutes";
import CampanhasRoutes from "@/router/global/CampanhasRoutes";
import BotsRoutes from "@/router/global/BotsRoutes";
import SearchsRoutes from "@/router/global/SearchsRoutes";
import ListsRoutes from "@/router/global/ListsRoutes";

const $Auth = new Auth()

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            name: "master",
            path: "/:catchAll(.*)",
            component: RouteView,
        },
        AuthRoutes,
        EmBreveRoutes,
        PerfilRoutes,
        BotsRoutes,
        CampanhasRoutes,
        SearchsRoutes,
        ListsRoutes

    ]
})

router.beforeEach(async (to, from, next) => {
    if(!$Auth.logado){
        await $Auth.setLogin()
        const RestrictedRoutes = $Auth.getRestrictedRoutes()
        if(RestrictedRoutes){
            router.addRoute('master',RestrictedRoutes)
        }

    }

    if ($Auth.logado && to.meta?.perm && !$Auth.checkPerm(to.meta.perm)) {
        $Auth.sair();
    }

    const routeName = to.name?.toString() ?? '';
    if ($Auth.logado && ['painel-auth-login', 'painel-auth-signup'].includes(routeName)) {
        next({path: '/'})
    } else if (!$Auth.logado && !['painel-auth-login', 'painel-auth-signup'].includes(routeName)) {
        next({name: 'painel-auth-login'})
    } else {
        next()
    }
})

export default router
