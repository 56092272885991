export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'painel-bots-listagem',
      displayName: 'Bots',
      meta: {
        icon: 'dashboard',
      },
    },
    {
      name: 'painel-lists-listagem',
      displayName: 'Listas',
      meta: {
        icon: 'list_alt',
      },
    },
  ],
}
