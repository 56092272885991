import RouteView from "@/router/route-view.vue";

export default
{
    name: 'painel-admin-groups',
    path: 'grupos',
    component: RouteView,
    redirect: 'master',
    children: [
        {
            name: 'painel-admin-groups-listagem',
            path: 'listagem',
            component: () => import('@/pages/restricted/admin/grupos/listagem/Grupos.vue'),
            meta: {
                name: 'Grupos',
                perm: 'manageGroups'
            },
        },
        {
            name: 'painel-admin-groups-new',
            path: 'novo',
            component: () => import('@/pages/restricted/admin/grupos/novo/NovoGrupo.vue'),
            meta: {
                name: 'Novo Grupo',
                perm: 'manageGroups'
            },
        },
        {
            name: 'painel-admin-groups-perms',
            path: 'permissoes/:id',
            component: () => import('@/pages/restricted/admin/grupos/permissoes/EditarPermissoes.vue'),
            meta: {
                name: 'Editar Permissões',
                perm: 'manageGroups'
            },
        },
    ]
}