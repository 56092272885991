import COLOR_THEMES from '@/services/vuestic-ui/global-config'
import {SendAlert} from "@/functions/Alert";

export async function ProcessaResponse(response:any) {
    if (response?.messages && Array.isArray(response.messages)){
        response.messages.forEach((m: string) =>{
            SendAlert(m,response.success ? COLOR_THEMES.colors.success :  COLOR_THEMES.colors.danger)
        })
    }
    return response;
}