export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'painel-afiliado-bots-listagem',
      displayName: 'Bots',
      meta: {
        icon: 'dashboard',
      },
    },
    {
      name: 'painel-afiliado-campanhas-listagem',
      displayName: 'Campanhas',
      meta: {
        icon: 'campaign',
      },
    },
    {
      name: 'painel-afiliado-searchs-listagem',
      displayName: 'Buscas',
      meta: {
        icon: 'manage_search',
      },
    },
    {
      displayName: 'Gestão',
      meta: {
        icon: 'settings',
      },
      children: [
        {
          name: 'painel-afiliado-users-listagem',
          displayName: 'Usuários',
        },
      ],
    },
  ],
}
