import AuthLayout from '@/layout/auth-layout.vue'

export default {
    name: 'painel-auth',
    path: '/auth',
    component: AuthLayout,
    redirect: 'master',
    children: [
        {
            name: 'painel-auth-login',
            path: 'login',
            component: () => import('@/pages/global/auth/login/Login.vue'),
        },
        // {
        //   name: 'painel-auth-signup',
        //   path: 'signup',
        //   component: () => import('@/pages/auth/signup/Signup.vue'),
        // },
        // {
        //     name: 'recover-password',
        //     path: 'recover-password',
        //     component: () => import('@/pages/auth/recover-password/RecoverPassword.vue'),
        // },
    ],
}