import PainelLayout from '@/layout/painel-layout.vue'

export default {
    name: 'painel-perfil',
    path: '/',
    component: PainelLayout,
    redirect: 'master',
    children: [
        {
            name: 'painel-perfil-senha',
            path: 'painel-perfil/senha',
            props: true,
            component: () => import('@/pages/global/perfil/TrocaSenha.vue'),
            meta: {
                name: 'Trocar Senha'
            }
        }
    ]
}