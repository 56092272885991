export function LoaderInit(){
    // @ts-ignore
    const LOADER = document.getElementById('loader');
    if(LOADER){
        LOADER.style.display = 'block';
    }

}
export function LoaderStop(){
    // @ts-ignore
    const LOADER = document.getElementById('loader');
    if(LOADER){
        LOADER.style.display = 'none';
    }
}