import RouteView from "@/router/route-view.vue";

export default
{
    name: 'painel-admin-users',
    path: 'usuarios',
    component: RouteView,
    redirect: 'master',
    children: [
        {
            name: 'painel-admin-users-listagem',
            path: 'listagem',
            component: () => import('@/pages/restricted/admin/users/historic/Users.vue'),
            meta: {
                name: 'Usuarios',
                perm: 'manageUsers'
            },
        },
        {
            name: 'painel-admin-users-new',
            path: 'novo',
            component: () => import('@/pages/restricted/admin/users/new/AddUser.vue'),
            meta: {
                name: 'Novo Usuario',
                perm: 'manageUsers'
            },
        },
        {
            name: 'painel-admin-users-edit',
            path: 'editar/:id',
            component: () => import('@/pages/restricted/admin/users/edit/EditUser.vue'),
            meta: {
                name: 'Editar Usuario',
                perm: 'manageUsers'
            },
        },
        {
            name: 'painel-admin-users-pass',
            path: 'trocar-senha/:id',
            component: () => import('@/pages/restricted/admin/users/password/ChangePassword.vue'),
            meta: {
                name: 'Trocar Senha',
                perm: 'manageUsers'
            },
        },
        {
            name: 'painel-admin-users-perms',
            path: 'permissoes/:id',
            component: () => import('@/pages/restricted/admin/users/permissoes/EditarPermissoes.vue'),
            meta: {
                name: 'Editar Permissões',
            },
        },
        {
            name: 'painel-admin-users-bots',
            path: 'bots/:id',
            component: () => import('@/pages/restricted/admin/users/bots/historic/EditarBots.vue'),
            meta: {
                name: 'Listar Bots',
            },
        },
        {
            name: 'painel-admin-users-bots-new',
            path: 'bots/novo',
            component: () => import('@/pages/restricted/admin/users/bots/new/AddBot.vue'),
            meta: {
                name: 'Novo Bot',
            },
        },
    ]
}