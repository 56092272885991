import PainelLayout from "@/layout/painel-layout.vue";

export default
{
    name: 'painel-campanhas',
    path: '/campanhas',
    component: PainelLayout,
    redirect: 'master',
    children: [
        {
            name: 'painel-campanhas-listagem',
            path: 'listagem',
            component: () => import('@/pages/global/campaigns/historic/Historic.vue'),
            meta: {
                name: ' Minhas Campanhas',
                perm: 'campaignGetAll'
            },
        },
        {
            name: 'painel-campanhas-new',
            path: 'novo',
            component: () => import('@/pages/global/campaigns/new-campaign/NewCampaign.vue'),
            meta: {
                name: 'Nova Campanha',
                perm: 'campaignGetAll'
            },
        },
        {
            name: 'painel-campanhas-edit',
            path: 'editar/:id',
            component: () => import('@/pages/global/campaigns/edit/EditCam.vue'),
            meta: {
                name: 'Editar Campanha',
                perm: 'campaignGetAll'
            },
        },
    ]
}