<template>
  <form @submit.prevent="onSubmit">
    <monta-row
      v-for="row in form.struct"
      :key="row.name"
      :all="data"
      :row="row"
      ref="rowRef"
      @update-input="updateInput"
    />
    <div v-if="!form?.data?.readonly || form?.data?.closeButton" class="row justify-center align-center va-spacing-x-1">
      <va-button v-if="form?.data?.closeButton" @click="this.$emit('close')" class="flex">{{ form?.data?.closeName ? form?.data?.closeName : 'Fechar' }}</va-button>
      <va-button v-if="!form?.data?.readonly"  type="submit" class="flex">{{ form?.data?.submitName ? form?.data?.submitName : 'Enviar' }}</va-button>
    </div>
  </form>
</template>

<script>
import MontaRow from "@/components/monta-form/MontaRow";
import COLOR_THEMES from '@/services/vuestic-ui/global-config'
import {SendAlert} from "@/functions/Alert";

export default {
  name: "MontaForm",
  components: {MontaRow},
  props: [
    'form'
  ],
  data() {
    return {
      data: {},
      validations: {}
    }
  },
  methods: {
    updateInput(input) {
      this.data[input.name] = input.val
      this.validations[input.name] = input.valid
    },
    updateAllInputs() {
      Object.entries(this.$refs.rowRef).forEach(([row]) => {
        this.$refs.rowRef[row].updateAllInputs();
      });
    },
    allIsValid() {
      let isValid = true;
      Object.entries(this.validations).forEach(([key]) => {
        if(!this.validations[key]) {isValid = false}
      });
      return isValid
    },
    onSubmit() {
      this.updateAllInputs();
      if (this.allIsValid()) {
        this.$emit('complete',  this.data)
        return true
      } else {
        SendAlert('Verifique os dados e tente novamente',COLOR_THEMES.colors.danger)
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>
