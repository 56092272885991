<template>
  <div class="row justify-center align-center va-spacing-x-1">
    <slot name="filters" />
    <va-input
      clearable
      class="flex"
      label="Filtrar"
      placeholder="Escreva um termo"
      v-model="filter"
    />
    <div class="flex">
      <va-progress-circle :indeterminate="autoRefresh.loading" v-model="autoRefresh.percent">
        <va-icon
          class="material-icons-outlined"
          size='16x'
          name="sync"
          :spin="autoRefresh.loading"
          @click="() => refresh(this, false)"
        />
      </va-progress-circle>
    </div>
    <div class="flex" v-if="table.novoRoute">
      <va-button icon-right="add_circle"  @click="$router.push({name: table.novoRoute})">
        {{ table.novoText ?? 'Novo' }}
      </va-button>
    </div>
    <div class="flex" v-if="table.ignorePersonalizar !== true" >
      <va-button icon-right="settings" @click="showModalPersonalizar = !showModalPersonalizar" >
        Personalizar
      </va-button>
    </div>
  </div>
  <br>
  <va-card outlined style="overflow: hidden">
    <va-inner-loading :loading="autoRefresh.loading">
      <va-data-table
        :class="table.name"
        :no-data-html="'Nenhum item encontrado'"
        :items="realyTable.items"
        :columns="columnsSelecteds"
        :hoverable="true"
        :striped="true"
        :filter="filter"
        :wrapper-size="450"
        sticky-header
        :virtual-scroller="virtualScroll"
        @filtered="filtered = $event.items"
      >
        <template v-for="(_, slot) in $slots" #[slot]="scope">
          <slot :name="slot" v-bind="scope || {}" />
        </template>
      </va-data-table>
    </va-inner-loading>
  </va-card>
  <va-modal
    v-model="showModalPersonalizar"
    size="large"
    blur
    fixed-layout
    hide-default-actions
  >
    <template #header>
      <div class="row justify-center align-center">
        <h4 class="flex va-h4">Personalizar</h4>
      </div>
      <br>
    </template>
    <template #default>
      <div class="row justify-center align-center va-spacing-x-4">
        <div class="flex" :key="col.key" v-for="col in realyTable.columns.map((c) =>{return {key: c.key,label: c.label }})">
          <va-checkbox v-model="realyTable.columnsSelectedsKeys[col.key]" :label="col.label" />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="row justify-center va-spacing-x-1">
        <va-button class="flex" color="secondary" @click="showModalPersonalizar = false">
          Voltar
        </va-button>
        <va-button class="flex" color="warning" @click="resetarEstrutura(this)">
          Resetar
        </va-button>
        <va-button class="flex"  @click="salvaEstrutura(this)">
          Salvar
        </va-button>
      </div>
    </template>
  </va-modal>
</template>

<script>
import {
  refresh,
  endRefresh,
  loadDataFromStorage,
  salvaEstrutura,
  resetarEstrutura
} from "./functions";

export default {
  name: "MontaListagem",
  emits: ["refresh"],
  props: {
    table: {
      type: Object
    }
  },
  mounted(){
    this.loaded = true;
    this.$emit('refresh', true)
    if(!this.table.ignorePersonalizar === true){
      loadDataFromStorage(this);
    } else {
      this.columnsSelecteds = this.table.columns
    }
  },
  data() {
    return {
      realyTable: this.table,
      filter: '',
      filtered: [],
      columnsSelecteds: [],
      showModalPersonalizar: false,
      virtualScroll: true,
      loaded: true,
      autoRefresh: {
        loading: true,
        percent: 0,
        sleepSeconds: this.table.autoRefreshSleepSeconds ?? 60
      }
    }
  },
  methods:{
    refresh,
    salvaEstrutura,
    resetarEstrutura
  },
  watch: {
    'realyTable.items'(){
      endRefresh(this);
    },
    'realyTable.refresh'(){
      if(this.realyTable.refresh === true){
        refresh(this);
      }
    },
    'filtered'(){
      this.virtualScroll = this.filtered.length > 8
    }
  },
  beforeUnmount(){
    this.loaded = false;
  },

}
</script>

<style scoped>

</style>