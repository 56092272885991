import RouteView from "@/router/route-view.vue";

export default
{
    name: 'painel-afiliado-bots',
    path: 'bots',
    component: RouteView,
    redirect: 'master',
    children: [
        {
            name: 'painel-afiliado-bots-listagem',
            path: 'listagem',
            component: () => import('@/pages/restricted/afiliado/bots/listagem/Bots.vue'),
            meta: {
                name: 'Todos os Bots',
            },
        },
    ]
}