<template>
  <va-sidebar
    v-if="pronto"
    :width="width"
    :minimized="minimized"
    :minimizedWidth="minimizedWidth"
    color="#fff"
  >
    <menu-minimized v-if="minimized && !isMobile()" :items="items" />
    <menu-accordion v-else :items="items" @clicou="this.$emit('clicou')"/>
  </va-sidebar>    
</template>

<script>
import { useGlobalConfig } from 'vuestic-ui';
import MenuAccordion from './menu/MenuAccordion.vue';
import MenuMinimized from './menu/MenuMinimized.vue';


export default {
  name: "painel-sidebar",
  components: {
    MenuAccordion,
    MenuMinimized,
  },
  props: {
    width: { type: String, default: '16rem' },
    color: { type: String, default: "secondary" },
    minimized: { type: Boolean, required: true },
    minimizedWidth: {
      type: Boolean,
      required: false,
      default: undefined
    },
  },
  created() {
    this.getItems()
  },
  methods:{
    isMobile() {
      return screen.width <= 760;
    },
    async checkPermRouteByName(name){
      const routeResolved = await this.$router.resolve({
        name: name,
      });
      return this.$Auth.checkPerm(routeResolved.meta.perm);
    },
    async getItems(){
      const SidebarItems = this.$Auth.getSidebarItems()
      for (const route of SidebarItems.routes) {
        if (Array.isArray(route.children)) {
          let filtredChilds = [];
          for (const childRoute of route.children) {
            if(!childRoute.name || await this.checkPermRouteByName(childRoute.name)) {
              filtredChilds.push(childRoute);
            }
          }
          if(filtredChilds.length > 0){
            route.children = filtredChilds;
            this.items.push(route)
          }
        }
        else if (!route.name || await this.checkPermRouteByName(route.name)){
          this.items.push(route)
        }
      }
      this.pronto = true
    }
  },
  data() {
    return {
      pronto: false,
      items: []
    };
  },
  computed: {
    computedClass() {
      return {
        "app-sidebar--minimized": this.minimized
      };
    },
    colors() {
      return useGlobalConfig().getGlobalConfig().colors
    },
  },
};
</script>

<style lang="scss">
.va-sidebar {
  .va-collapse__body {
    margin-top: 0 !important;
  }

  &__menu {
    padding: 2rem 0;
    transition: var(--va-sidebar-transition);
  }

  &-item {
    &-content {
      padding: 0.75rem 1rem;
    }

    &__icon {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

<style lang="scss" scoped>
.va-sidebar {
  flex-shrink: 0;
}

// .va-sidebar--minimized {
//   width: auto !important;
// }
</style>
