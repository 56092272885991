import RouteView from "@/router/route-view.vue";

export default
{
    name: 'painel-afiliado-campanhas',
    path: 'campanhas',
    component: RouteView,
    redirect: 'master',
    children: [
        {
            name: 'painel-afiliado-campanhas-listagem',
            path: 'listagem',
            component: () => import('@/pages/restricted/afiliado/campaigns/historic/Historic.vue'),
            meta: {
                name: 'Todas as Campanhas',
                perm: 'campaignGetAll'
            },
        },
    ]
}