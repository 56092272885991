import PainelLayout from '@/layout/painel-layout.vue'
import EmBreve from '@/pages/global/breve/EmBreve.vue'

export default
{
    name: 'painel-embreve',
    path: '/',
    component: PainelLayout,
    redirect: 'master',
    children: [
        {
            name: 'painel-embreve-embreve',
            path: 'em-breve',
            component: EmBreve,
        },
    ]
}