import PainelLayout from '@/layout/painel-layout.vue'
import BotsRoutes from "./BotsRoutes";
import CampanhasRoutes from "./CampanhasRoutes";
import SearchsRoutes from "./SearchsRoutes";
import UsersRoutes from "./UsersRoutes";

export default
{
    name: 'painel-afiliado',
    path: '/afiliado',
    component: PainelLayout,
    redirect: 'master',
    children: [
        BotsRoutes,
        CampanhasRoutes,
        SearchsRoutes,
        UsersRoutes
    ]
}