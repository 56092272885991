import RouteView from "@/router/route-view.vue";

export default
{
    name: 'painel-afiliado-searchs',
    path: 'buscas',
    component: RouteView,
    redirect: 'master',
    children: [
        {
            name: 'painel-afiliado-searchs-listagem',
            path: 'afiliado/buscas',
            component: () => import('@/pages/restricted/afiliado/searchs/historic/Historic.vue'),
            meta: {
                name: 'Todas as Buscas',
                perm: 'searchGetAll'
            },
        },
    ]
}