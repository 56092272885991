<template>
  <div class="app-layout">
    <navbar @change-side-bar="onCloseSidebarButtonClick" :is-sidebar-minimized="isSidebarMinimized"/>
    <div class="app-layout__content">
      <div class="app-layout__sidebar-wrapper" :class="{ minimized: this.isSidebarMinimized }">
        <div v-if="isFullScreenSidebar" class="d-flex justify--end">
          <va-button
            class="px-4 py-4 material-icons-outlined"
            icon="description"
            flat
            color="dark"
          />
        </div>
        <sidebar
          v-if="!isSidebarCloseable || !isSidebarMinimized"
          :width="sidebarWidth"
          :minimized="isSidebarMinimized"
          :minimizedWidth="sidebarMinimizedWidth"
          :hoverable="isSidebarCloseable"
          @clicou="isSidebarMinimized = isMobile ? true : isSidebarMinimized"
        />
      </div>
      <div class="app-layout__page">
        <div class="app-layout__page__title">
          <h2 class="va-h2">{{$route.meta.name ?? $route.name}}</h2>
        </div>
        <div class="layout fluid" style="padding: 0 12px">
          <router-view/>
        </div>
      </div>
      <va-backtop target=".app-layout__page"
        vertical-offset="5%"
        horizontal-offset="5%"
        horizontal-position="right"
        vertical-position="bottom"
        :visibility-height="40"
        :speed="50"/>
    </div>

  </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import Sidebar from '@/components/sidebar/SidebarPainel';
import Navbar from '@/components/navbar/Navbar.vue';


export default {
  name: 'app-layout',
  components: {
    Navbar, Sidebar
  },
  methods: {
    onCloseSidebarButtonClick() {
      this.isSidebarMinimized = !this.isSidebarMinimized;
    },
  },
  setup() {
    const mobileBreakPointPX = 640
    const tabletBreakPointPX = 768

    const sidebarWidth = ref('16rem')
    const sidebarMinimizedWidth = ref(undefined)
    const isSidebarCloseable = ref(true)
    const isSidebarMinimized = ref(false)
    const isMobile = ref(false)
    const isTablet = ref(false)
    const checkIsTablet = () => window.innerWidth <= tabletBreakPointPX
    const checkIsMobile = () => window.innerWidth <= mobileBreakPointPX

    const onResize = () => {

      isMobile.value = checkIsMobile()
      isTablet.value = checkIsTablet()
      isSidebarCloseable.value = !isMobile.value
      isSidebarMinimized.value = isMobile.value
      sidebarMinimizedWidth.value = isMobile.value ? 0 : '4rem'
      sidebarWidth.value = isTablet.value ? '100%' : '16rem'
    }

    onMounted(() => {
      window.addEventListener('resize', onResize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    onResize()

    const isFullScreenSidebar = computed(() => isTablet.value && false)

    return {
      sidebarWidth, sidebarMinimizedWidth,
      isFullScreenSidebar, isSidebarCloseable,
      isSidebarMinimized,isMobile
    }
  }
}
</script>

<style lang="scss">
.va-sidebar--minimized {
  .va-collapse__body-wrapper {
    display: none;
  }

  .va-sidebar__item__content > :last-child {
    display: none;
  }
}
.va-sidebar__item.va-sidebar-item.va-sidebar-item--active {
  background-color: var(--va-primary) !important;
}
$mobileBreakPointPX: 640px;
$tabletBreakPointPX: 768px;

.app-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  &__navbar {
    min-height: 4rem;
  }

  &__content {
    display: flex;
    height: calc(100vh - 4rem);
    flex: 1;

    @media screen and (max-width: $tabletBreakPointPX) {
      height: calc(100vh - 6.5rem);
    }

    .app-layout__sidebar-wrapper {
      position: relative;
      height: 100%;
      background: var(--va-white);
      box-shadow: var(--va-box-shadow);

      @media screen and (max-width: $tabletBreakPointPX) {
        &:not(.minimized) {
          width: 100%;
          height: calc(100vh - 4rem);
          position: fixed;
          bottom: 0;
          z-index: 999;
        }

        .va-sidebar:not(.va-sidebar--minimized) {
          // Z-index fix for preventing overflow for close button
          z-index: -1;
          .va-sidebar__menu {
            padding: 0;
          }
        }
      }
    }
  }
  &__page {
    flex-grow: 2;
    overflow-y: scroll;

    &__title {
      background-image: url('/img/background.jpg');
      background-size: auto;
      background-position: center center;
      background-attachment: fixed;

      h2 {
        text-align: center;
        padding: 20px 0 120px 0;
        margin-top: 0;
        margin-bottom: -100px;
        background: rgba(var(--va-primary-rgb),0.75) !important;
        color:white;
        text-transform: capitalize;
      }
    }
  }
}
</style>
