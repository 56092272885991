import RouteView from "@/router/route-view.vue";

export default
{
    name: 'painel-afiliado-users',
    path: 'usuarios',
    component: RouteView,
    redirect: '/',
    children: [
        {
            name: 'painel-afiliado-users-listagem',
            path: 'listagem',
            component: () => import('@/pages/restricted/afiliado/users/historic/Users.vue'),
            meta: {
                name: 'Usuarios',
                perm: 'manageUsers'
            },
        },
        {
            name: 'painel-afiliado-users-new',
            path: 'novo',
            component: () => import('@/pages/restricted/afiliado/users/new/AddUser.vue'),
            meta: {
                name: 'Novo Usuario',
                perm: 'manageUsers'
            },
        },
        {
            name: 'painel-afiliado-users-edit',
            path: 'editar/:id',
            component: () => import('@/pages/restricted/afiliado/users/edit/EditUser.vue'),
            meta: {
                name: 'Editar Usuario',
                perm: 'manageUsers'
            },
        },
        {
            name: 'painel-afiliado-users-pass',
            path: 'trocar-senha/:id',
            component: () => import('@/pages/restricted/afiliado/users/password/ChangePassword.vue'),
            meta: {
                name: 'Trocar Senha',
                perm: 'manageUsers'
            },
        },
        {
            name: 'painel-afiliado-users-bots',
            path: 'bots/:id',
            component: () => import('@/pages/restricted/afiliado/users/bots/historic/EditarBots.vue'),
            meta: {
                name: 'Listar Bots',
            },
        },
        {
            name: 'painel-afiliado-users-bots-new',
            path: 'bots/novo',
            component: () => import('@/pages/restricted/afiliado/users/bots/new/AddBot.vue'),
            meta: {
                name: 'Novo Bot',
            },
        },
    ]
}