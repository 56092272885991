export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'painel-admin-bots-listagem',
      displayName: 'Meus bots',
      meta: {
        icon: 'dashboard',
      },
    },
    {
      name: 'painel-admin-campanhas-listagem',
      displayName: 'Campanhas',
      meta: {
        icon: 'campaign',
      },
    },
    {
      name: 'painel-admin-searchs-listagem',
      displayName: 'Buscas',
      meta: {
        icon: 'manage_search',
      },
    },
    {
      displayName: 'Gestão',
      meta: {
        icon: 'settings',
      },
      children: [
        {
          name: 'painel-admin-users-listagem',
          displayName: 'Usuários',
        },
        {
          name: 'painel-admin-groups-listagem',
          displayName: 'Grupos',
        },
      ],
    },
    // {
    //   name: 'pages',
    //   displayName: 'Minhas Faturas',
    //   meta: {
    //     icon: 'vuestic-iconset-files',
    //   }
    // },
    // {
    //   name: 'pages',
    //   displayName: 'Suporte',
    //   meta: {
    //     icon: 'vuestic-iconset-files',
    //   }
    // },
  ],
}
