<template>
  <router-view/>
</template>


<script>
export default {
  mounted(){
    if (this.$route.name === 'master') {
      const realRoute = this.$router.resolve(this.$route.path);
      if(realRoute.name !== 'master') {
        this.$router.push({'path': this.$route.path})
      } else {
        this.$router.push({'name': this.$Auth.getDefaultRoute()})
      }
    }
  },
}
</script>
