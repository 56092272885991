import { createVuestic } from 'vuestic-ui';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import moment from 'moment'
import {Auth} from "@/functions/Auth";
import MontaForm from "@/components/monta-form/MontaForm.vue";
import MontaListagem from "@/components/monta-listagem/MontaListagem.vue";

moment.locale('pt-br');

const app = createApp(App)
app.use(router)
app.use(createVuestic({
  config: {
    colors: {
      variables: {
        primary: process.env.VUE_APP_COLOR_PRIMARY_HEX
      }
    },
  },
}), vuesticGlobalConfig)
app.component('MontaForm', MontaForm)
app.component('MontaListagem', MontaListagem)
app.config.globalProperties.$Auth = new Auth()
app.mount('#app')