import PainelLayout from "@/layout/painel-layout.vue";

export default
{
    name: 'painel-lists',
    path: '/listas',
    component: PainelLayout,
    redirect: 'master',
    children: [
        {
            name: 'painel-lists-listagem',
            path: 'listagem',
            component: () => import('@/pages/global/lists/historic/Lists.vue'),
            meta: {
                name: ' Minhas Listas',
                perm: 'listGetAll'
            },
        },
        {
            name: 'painel-lists-new',
            path: 'novo',
            component: () => import('@/pages/global/lists/new/NewList.vue'),
            meta: {
                name: 'Nova Lista',
                perm: 'listGetAll'
            },
        },
        {
            name: 'painel-lists-edit',
            path: 'editar/:id',
            component: () => import('@/pages/global/lists/edit/EditList.vue'),
            meta: {
                name: 'Editar Lista',
                perm: 'listGetAll'
            },
        },
    ]
}