import PainelLayout from "@/layout/painel-layout.vue";

export default
{
    name: 'painel-searchs',
    path: '/buscas',
    component: PainelLayout,
    redirect: 'master',
    children: [
        {
            name: 'painel-searchs-listagem',
            path: 'listagem',
            component: () => import('@/pages/global/searchs/historic/Historic.vue'),
            meta: {
                name: 'Minhas Buscas',
                perm: 'searchGetAll'
            },
        },
        {
            name: 'painel-searchs-new',
            path: 'novo',
            component: () => import('@/pages/global/searchs/new-search/NewSearch.vue'),
            meta: {
                name: 'Nova Busca',
                perm: 'searchGetAll'
            },
        },
    ]
}