import PainelLayout from '@/layout/painel-layout.vue'
import BotsRoutes from "@/router/restricted/admin/BotsRoutes";
import CampanhasRoutes from "@/router/restricted/admin/CampanhasRoutes";
import GroupsRoutes from "@/router/restricted/admin/GroupsRoutes";
import SearchsRoutes from "@/router/restricted/admin/SearchsRoutes";
import UsersRoutes from "@/router/restricted/admin/UsersRoutes";

export default
{
    name: 'painel-admin',
    path: '/admin',
    component: PainelLayout,
    redirect: 'master',
    children: [
        BotsRoutes,
        CampanhasRoutes,
        GroupsRoutes,
        SearchsRoutes,
        UsersRoutes
    ]
}