<template>
  <div :class="`input__fixWidth ${item.readonly ? 'input__readonly' : ''} ${item.lineCounter ? 'input__lineCounter' : ''} flex md${item.col}`" :id="'input-' + item.name">
    <div v-if="item.type === 'title'">
      <hr v-if="item.topHr === true"/>
      <h6 class="display-6">{{ item.value }}</h6>
    </div>
    <div v-else-if="item.type === 'va-file-upload'">
      <label v-if="item.label" aria-hidden="true" class="va-input-upload__label"> {{ item.label }}</label>
      <va-file-upload
        v-model="value"
        dropzone
        v-on="listeners"
        v-bind="$attrs"
        :class="`mb-3 input__generated ${item.customClass ?? ''}  ${errors.length > 0 ? 'va-input-upload_error' : ''}  ${success? 'va-input-upload_success' : ''}` "
        :id="item.name"
        :name="item.name"
      />
      <div v-if="errors.length > 0" class="va-input-wrapper__message-list-wrapper" style="margin-top: -15px;">
        <div class="va-message-list" >
          <div v-for="e in errors" :key="e" class="va-message-list__message va-upload-message__error">{{ e }}</div>
        </div>
      </div>
    </div>
    <div v-else-if="item.type === 'checkbox'">
      <va-checkbox
        clearable
        v-model="value"
        v-on="listeners"
        v-bind="$attrs"
        :class="`mb-3 input__generated ${item.customClass ?? ''}`"
        :loading="false"
        :immediate-validation="false"
        :label="item.label"
        :id="item.name"
        :name="item.name"
        :type="item.type"
        :readonly="item.readonly"
        :rules="[validate]"
        :success="success"
        :error="errors.length > 0"
        :error-messages="errors"
        :error-count="errors.length"
      />
    </div>
    <div v-else-if="item.type === 'select'">
      <va-select
        clearable
        v-model="value"
        v-on="listeners"
        v-bind="$attrs"
        max-height="512px"
        :class="`mb-3 input__generated ${item.customClass ?? ''}`"
        :loading="false"
        :immediate-validation="false"
        :options="item.options"
        :label="item.label"
        :searchable="item.searchable"
        :id="item.name"
        :name="item.name"
        :type="item.type"
        :readonly="item.readonly"
        :rules="[validate]"
        :success="success"
        :error="errors.length > 0"
        :error-messages="errors"
        :error-count="errors.length"
      >
        <template #prependInner v-if="item.leftIcon">
          <va-icon class="material-icons-outlined" size='14px'>{{ item.leftIcon }}</va-icon>
        </template>
      </va-select>
    </div>
    <div v-else-if="item.type === 'textarea'">
      <va-input
        :clearable="!item.readonly"
        v-model="value"
        v-on="listeners"
        v-bind="$attrs"
        :class="`mb-3 input__generated ${item.customClass ?? ''}`"
        :loading="false"
        :immediate-validation="false"
        :mask="isFunction(item.mask) ? item.mask(value) : item.mask"
        :label="item.label"
        :id="item.name"
        :name="item.name"
        :type="item.type"
        :placeholder="item.placeholder"
        :readonly="item.readonly"
        :rules="[validate]"
        :success="success && !item.readonly"
        :error="errors.length > 0"
        :error-messages="errors"
        :error-count="errors.length"
        :disabled="item.disabled"
        :min-rows="item.minRows"
        wrap="off"
        autosize
      >
        <template #prependInner v-if="item.leftIcon">
          <va-icon class="material-icons-outlined" size='14px'>{{ item.leftIcon }}</va-icon>
        </template>
        <template #prependInner v-else-if="item.lineCounter">
          <div style="width: 40px;">
            <textarea
              v-model="countValue"
              id='lineCounter'
              readonly
            />
          </div>
        </template>
        <template #appendInner v-if="success">
          <va-icon v-if="!item.readonly" class="material-icons-outlined" size='14px' color="success">check_circle</va-icon>
        </template>
      </va-input>
    </div>
    <div v-else>
      <va-input
        :clearable="!item.readonly"
        v-model="value"
        v-on="listeners"
        v-bind="$attrs"
        :class="`mb-3 input__generated ${item.customClass ?? ''}`"
        :loading="false"
        :immediate-validation="false"
        :mask="isFunction(item.mask) ? item.mask(value) : item.mask"
        :label="item.label"
        :id="item.name"
        :name="item.name"
        :type="item.type"
        :placeholder="item.placeholder"
        :readonly="item.readonly"
        :rules="[validate]"
        :success="success && !item.readonly"
        :error="errors.length > 0"
        :error-messages="errors"
        :error-count="errors.length"
        :disabled="item.disabled"
      >
        <template #prependInner v-if="item.leftIcon">
          <va-icon class="material-icons-outlined" size='14px'>{{ item.leftIcon }}</va-icon>
        </template>
        <template #appendInner v-if="success">
          <va-icon v-if="!item.readonly" class="material-icons-outlined" size='14px' color="success">check_circle</va-icon>
        </template>
      </va-input>
    </div>
  </div>
</template>
<script>
import {isFunction} from '@/functions/Validations';

export default {
  name: "InputText",
  props: [
    'item',
    'all'
  ],
  data() {
    return {
      isFunction,
      value: this.item.value,
      mask: typeof this.item.mask === 'function' ?  this.item.mask() : '',
      success: false,
      errors: [],
      lineCount: 1,
      countValue: "1."
    };
  },
  computed: {
    listeners() {
      return {
        change: this.updateInput,
        focus: this.focus,
      };
    },
  },
  methods: {
    updateInput() {
      this.$emit("updateInput", {name: this.item.name, val: this.value, valid: this.valid});
    },
    changeValid(errors = []){
      this.errors = errors;
      this.valid = this.success = errors.length === 0
    },
    validate() {
      let errors = [];
      //Required
      if (this.item.required && (this.value === undefined || this.value === null || !this.value.length > 0) && this.value !== true) {
        this.changeValid(false);
        errors.push( 'Campo obrigatório' )
      }
      //Email
      //@todo move to validations.ts
      if (this.item.type === 'email') {
        const re = /\S+@\S+\.\S+/;
        if (!re.test(String(this.value).toLowerCase())) {
          this.changeValid(false);
          errors.push( 'E-mail inválido' )
        }
      }
      //Callback
      if (typeof this.item?.validateFunc === "function") {
        const lambdaResult = this.item.validateFunc(this.value, this.item, this.all ?? false);
        if (lambdaResult !== true) {
          errors = errors.concat(lambdaResult)
        }
      }

      this.changeValid(errors);
      return errors.length === 0;
    },
    lineCounter(){
      const codeEditor = document.getElementById(this.item.name);
      const lineCounter = document.getElementById('lineCounter');
      function outputsize () {
        lineCounter.style.height = codeEditor.offsetHeight + 'px'
      }
      codeEditor.addEventListener('scroll', () => {
        lineCounter.scrollTop = codeEditor.scrollTop;
        lineCounter.scrollLeft = codeEditor.scrollLeft;
      });
      outputsize();
      new ResizeObserver(outputsize).observe(codeEditor)
    }
  },
  mounted(){
    if(this.item.lineCounter){
      this.lineCounter();
    }
    const textoDoArrasESolta = document.querySelector(`#${this.item.name} .va-file-upload__field__text`);
    if(textoDoArrasESolta){
      textoDoArrasESolta.innerText = 'Solte seus arquivos aqui ou'
    }
    const botaoDeUpload = document.querySelector(`#${this.item.name} .va-file-upload__field .va-button__content`);
    if(botaoDeUpload){
      botaoDeUpload.innerText = 'Clique para selecionar';
    }
  },
  watch:{
    value: function () {
      if(this.item.lineCounter){
        let lineCount = this.value.split('\n').length;
        let outarr = [];
        if(lineCount > 0) {
          if (this.lineCount !== lineCount) {
            for (let x = 0; x < lineCount; x++) {
              outarr[x] = (x + 1) + '.';
            }
            this.countValue = outarr.join('\n');
          }
          this.lineCount = lineCount;
        }
      }

      if (this.item.type === 'va-file-upload' || this.item.type === 'checkbox'){
        this.validate();
        if(this.value.length > 1 && !this.item.multiple){
          this.value = this.value.slice(-1);
        }
      }
    }
  }
};
</script>
<style lang="scss">
.input__fixWidth{
  width: 100%;
  .input__generated{
    padding-right: 2px;
    padding-left: 2px;
    width: 100%;

    .va-input__icons {
      order: 10;
      padding-right: 0;
      padding-left: 2px;
    }
  }
  .va-input-wrapper__field > i {
    padding-right: 0;
  }
}
.va-input-upload_error{
  background: var(--va-input-error-background) !important;
  border-color: var(--va-input-error-color) !important;
  border: var(--va-input-border-width) solid var(--va-input-color);
}
.va-input-upload_success{
  background: var(--va-input-success-background) !important;
  border-color: var(--va-input-success-color) !important;
  border: var(--va-input-border-width) solid var(--va-input-color);
}

.va-upload-message__error{
  color: var(--va-input-error-color) !important;
}
.va-input-upload__label {
  color:var(--va-primary) !important;
  display: block;
  font-size: var(--va-input-container-label-font-size);
  font-weight: var(--va-input-container-label-font-weight);
  height: 12px;
  margin-left: 14px;
  letter-spacing: var(--va-input-container-label-letter-spacing,var(--va-letter-spacing));
  line-height: var(--va-input-container-label-line-height);
  max-width: var(--va-input-container-label-max-width);
  overflow: hidden;
  padding-top: 1px;
  position: absolute;
  text-overflow: ellipsis;
  text-transform: var(--va-input-container-label-text-transform);
  margin-top: 15px !important;
  transform: translateY(-100%);
  transform-origin: top left;
  white-space: nowrap;
  z-index: 9;
}
#soli_files {
  background-color: #f6f7f6 !important;
}

.input__lineCounter {
  textarea {
    white-space: pre;
  }
  #lineCounter {
    border-color: transparent;
    overflow-y: hidden;
    box-shadow: none;
    background-color: transparent;
    position: absolute;
    width: 35px;
    top: 9px;
    white-space: pre-wrap;
    height: 100%;
    line-height: 20px;
    resize: none;
    text-align: right;
    font-weight: bold;
  }
  .va-input-wrapper__text {
    overflow: visible;
  }
  .va-input-wrapper--labeled .va-input-wrapper__label{
    margin-left: -45px;
  }
}
</style>
