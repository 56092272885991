import PainelLayout from "@/layout/painel-layout.vue";

export default
{
    name: 'painel-bots',
    path: '/bots',
    component: PainelLayout,
    redirect: 'master',
    children: [
        {
            name: 'painel-bots-listagem',
            path: 'listagem',
            component: () => import('@/pages/global/bots/listagem/Bots.vue'),
            meta: {
                name: 'Meus Bots',
            },
        },
        {
            name: 'painel-bots-detalhes',
            path: 'novo',
            component: () => import('@/pages/global/bots/detalhes/BotDetalhes.vue'),
            meta: {
                name: 'Detalhes',
            },
        },
    ]
}