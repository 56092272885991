<template>
  <div id="loader">
    <div class="Spinner">

      <semipolar-spinner
        :animation-duration="1200"
        :size="100"
        color="white"
      />
      <div style="display: none;" id="isRestarting" data-value="false"></div>
    </div>
  </div>
  <router-view/>
</template

>
<script>
window.onbeforeunload  = function () {
  document.getElementById('isRestarting').setAttribute("data-value","true");
};

const root = document.documentElement;
root.style.setProperty("--va-primary-rgb", process.env.VUE_APP_COLOR_PRIMARY_RGB);

import { SemipolarSpinner } from 'epic-spinners'

export default {
  components: {
    SemipolarSpinner
  }
}
</script>
<style lang="scss">
@import '~@/sass/main.scss';
</style>
